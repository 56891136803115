import React from "react"
const defaultState = {
  cartItems: [],
  addToCart: () => {},
  deleteFromCart: () => {},
}
const CartContext = React.createContext(defaultState)

class CartProvider extends React.Component {
  state = {
    cartItems: !localStorage.getItem("cartItems")
      ? []
      : JSON.parse(localStorage.getItem("cartItems")), // Tutaj była pusta lista [] jako default state
  }
  addToCart = item => {
    let cartItems = this.state.cartItems

    console.log(item)
    this.setState(
      state => {
        cartItems = state.cartItems.concat(item)
        return {
          cartItems,
        }
      },
      () => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems)) //callback nie dziala
        console.log(cartItems)
        console.log(JSON.parse(localStorage.getItem("cartItems")))
      }
    )
  }
  deleteFromCart = item => {
    let cartItems = this.state.cartItems
    console.log(item)
    this.setState(
      state => {
        state.cartItems.splice(
          cartItems.findIndex(i => i.name === item),
          1
        )

        return {
          cartItems,
        }
      },
      () => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems)) //delete dziala dobrze
      }
    )
    console.log(cartItems)
    console.log(JSON.parse(localStorage.getItem("cartItems")))
  }

  render() {
    const { children } = this.props
    const { cartItems } = this.state
    return (
      <CartContext.Provider
        value={{
          cartItems,
          addToCart: this.addToCart,
          deleteFromCart: this.deleteFromCart,
        }}
      >
        {children}
      </CartContext.Provider>
    )
  }
}
export default CartContext
export { CartProvider }
