// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-finish-js": () => import("./../../../src/pages/cart-finish.js" /* webpackChunkName: "component---src-pages-cart-finish-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapy-js": () => import("./../../../src/pages/mapy.js" /* webpackChunkName: "component---src-pages-mapy-js" */),
  "component---src-pages-plakaty-archikony-js": () => import("./../../../src/pages/plakaty/archikony.js" /* webpackChunkName: "component---src-pages-plakaty-archikony-js" */),
  "component---src-pages-plakaty-inne-js": () => import("./../../../src/pages/plakaty/inne.js" /* webpackChunkName: "component---src-pages-plakaty-inne-js" */),
  "component---src-pages-plakaty-js": () => import("./../../../src/pages/plakaty.js" /* webpackChunkName: "component---src-pages-plakaty-js" */),
  "component---src-pages-plakaty-lodzkie-js": () => import("./../../../src/pages/plakaty/lodzkie.js" /* webpackChunkName: "component---src-pages-plakaty-lodzkie-js" */),
  "component---src-pages-plakaty-warszawskie-js": () => import("./../../../src/pages/plakaty/warszawskie.js" /* webpackChunkName: "component---src-pages-plakaty-warszawskie-js" */),
  "component---src-pages-print-info-js": () => import("./../../../src/pages/print-info.js" /* webpackChunkName: "component---src-pages-print-info-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-shippinfo-js": () => import("./../../../src/pages/shippinfo.js" /* webpackChunkName: "component---src-pages-shippinfo-js" */),
  "component---src-templates-mapa-js": () => import("./../../../src/templates/mapa.js" /* webpackChunkName: "component---src-templates-mapa-js" */),
  "component---src-templates-plakat-js": () => import("./../../../src/templates/plakat.js" /* webpackChunkName: "component---src-templates-plakat-js" */),
  "component---src-templates-projekt-js": () => import("./../../../src/templates/projekt.js" /* webpackChunkName: "component---src-templates-projekt-js" */)
}

